// REACT
import React from "react";
// AMPLIFY
import { Amplify, API, Storage } from 'aws-amplify';
import { listJobs }				 from './graphql/queries';
import {
	useTheme,
	View,
	Tabs, 
	TabItem,
} from '@aws-amplify/ui-react';
// APP
import Header		from './Header';
import Footer		from './Footer';
import Help			from './Help';
import MyJobs		from './MyJobs';
import CreateJob	from './CreateJob';

Storage.configure({ level: 'private' });

function forceSampleValues(data) {
	data.forEach(function(job, i) { 
		data[i].id = "sample";
		data[i].identity = "sample";
		data[i].translateKey = "{\"sample\":\"sample\"}";
	})
	return data;
}

function sortDataByCreatedAt(data) {
	data.sort((a,b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0))
	return data;
}

export default function App(props) {
	/* Initial state: jobs */
	const [jobs, updateJobs] = React.useState([]);

	/* Fetch jobs on load */
	React.useEffect(() => {
		let data;
		async function fetchJobs() {
			console.log("Loading job data");
			await API.graphql({ query: listJobs, authMode: 'AMAZON_COGNITO_USER_POOLS' }).then(response => {
				console.log("Loading job data: Checking for Cloud jobs");
				console.log(response);
				data = response.data.listJobs.items;
				// If no data, use sampleData
				if ( data === undefined || data.length === 0 ){
					console.log("Loading job data: No cloud jobs");
					try {
						data = require("./jobData.json");
						console.log("Loading job data: Using user sample jobs");
					} catch {
						data = require("./sampleJobData.json");
						console.log("Loading job data: Using project sample jobs");
					}
					data = forceSampleValues(data);
				}
				data = sortDataByCreatedAt(data);
				console.log("Loading job data: Using jobs");
				console.log(data);
				updateJobs(data);
			}).catch(e => {
				console.log(e); // TODO !! RUNS BEFORE LOGIN !! // Error: No current user
				console.log("ERROR: " + e.errors[0].message);
			});
		}
		fetchJobs();

	}, []);

	const [tabIndex, setTabIndex] = React.useState(0);
	const { tokens } = useTheme();
	return (
		<View
			backgroundColor={tokens.colors.background.secondary}
			padding={tokens.space.large}
		>
			<View
				maxWidth="1000px"
				margin="0 auto"
			>
				<Header />
				<Tabs
					justifyContent="flex-start"
					currentIndex={tabIndex}
					onChange={(i) => setTabIndex(i)}
				>
					<TabItem title="My Translations">
						<MyJobs jobs={jobs} />
					</TabItem>
					<TabItem title="New Translation">
						<CreateJob
							updateJobs={updateJobs}
							jobs={jobs}
						/>
					</TabItem>
					<TabItem className="helpTab" title="Help">
						<Help />
					</TabItem>
					<TabItem title="Sign Out" 
					onClick={props.signOut}
					>
						<View
							backgroundColor={tokens.colors.background.secondary}
							padding={tokens.space.medium}
						>
							<p>Signing out...</p>
						</View>
					</TabItem>
				</Tabs>
				<Footer />
			</View>
		</View>
	);
}