/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      id
      identity
      name
      languageSource
      languageTargets
      contentType
      createdAt
      updatedAt
      sourceKey
      jobStatus
      sourceStatus
      piiStatus
      translateStatus
      translateKey
      translateCallback
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        identity
        name
        languageSource
        languageTargets
        contentType
        createdAt
        updatedAt
        sourceKey
        jobStatus
        sourceStatus
        piiStatus
        translateStatus
        translateKey
        translateCallback
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncJobs = /* GraphQL */ `
  query SyncJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncJobs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        identity
        name
        languageSource
        languageTargets
        contentType
        createdAt
        updatedAt
        sourceKey
        jobStatus
        sourceStatus
        piiStatus
        translateStatus
        translateKey
        translateCallback
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getHelp = /* GraphQL */ `
  query GetHelp($id: ID!) {
    getHelp(id: $id) {
      title
      description
      link
      order
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHelps = /* GraphQL */ `
  query ListHelps(
    $filter: ModelHelpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHelps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        title
        description
        link
        order
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHelps = /* GraphQL */ `
  query SyncHelps(
    $filter: ModelHelpFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHelps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        title
        description
        link
        order
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
