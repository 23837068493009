/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      identity
      name
      languageSource
      languageTargets
      contentType
      createdAt
      updatedAt
      sourceKey
      jobStatus
      sourceStatus
      piiStatus
      translateStatus
      translateKey
      translateCallback
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      id
      identity
      name
      languageSource
      languageTargets
      contentType
      createdAt
      updatedAt
      sourceKey
      jobStatus
      sourceStatus
      piiStatus
      translateStatus
      translateKey
      translateCallback
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      id
      identity
      name
      languageSource
      languageTargets
      contentType
      createdAt
      updatedAt
      sourceKey
      jobStatus
      sourceStatus
      piiStatus
      translateStatus
      translateKey
      translateCallback
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createHelp = /* GraphQL */ `
  mutation CreateHelp(
    $input: CreateHelpInput!
    $condition: ModelHelpConditionInput
  ) {
    createHelp(input: $input, condition: $condition) {
      title
      description
      link
      order
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHelp = /* GraphQL */ `
  mutation UpdateHelp(
    $input: UpdateHelpInput!
    $condition: ModelHelpConditionInput
  ) {
    updateHelp(input: $input, condition: $condition) {
      title
      description
      link
      order
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHelp = /* GraphQL */ `
  mutation DeleteHelp(
    $input: DeleteHelpInput!
    $condition: ModelHelpConditionInput
  ) {
    deleteHelp(input: $input, condition: $condition) {
      title
      description
      link
      order
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
