// REACT
import React    from 'react';
import { createRoot } from 'react-dom/client';

// AMPLIFY
import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react';
import config  from './aws-exports'
import '@aws-amplify/ui-react/styles.css';
// APP
import App from './App';
import './index.css'; // TODO Should this be App.css?

Amplify.configure(config)

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<Authenticator
			variation="modal"
			hideSignUp={true}
		>
			{({ signOut, user }) => (  
				<App user={user} signOut={signOut} />
			)}
		</Authenticator>
	</React.StrictMode>
);