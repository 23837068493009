// REACT
import React 			from 'react';
import { FcDocument }	from 'react-icons/fc';
// AMPLIFY
import {
	View,
	Heading,
	useTheme,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

let logo
try {
	logo = require('./logo.png');
} catch {
	logo = false;
}

export default function Header() {
	const { tokens } = useTheme();
	return (
		<View
			backgroundColor={tokens.colors.background.secondary}
			padding={tokens.space.medium}
		>
			<div>
				<Heading level={1}>
					{logo && <span className="align-with-text"><img src={logo} /></span>}
					{!logo && <FcDocument className="align-with-text" />}
					<span className="align-with-text">&nbsp;Document Translation</span>
				</Heading>
			</div>
		</View>
	)
}